import { useEffect, useRef } from "react";
import { ListSkeleton } from "../../skeletons/List";
import axios from "axios";
import { useState } from "react";
import DataTable from "../../components/Table";
import { Button, Container, Dialog, DialogTitle, List, ListItem, ListItemButton, Menu, MenuItem, Typography } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import { useNavigate } from "react-router-dom";
import { AuthHelper } from "../../controllers/AuthHelper";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSnackBar } from "../../providers/consumers/useSnackBar";


const ListLeads = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder('leads.add');
    const [role, setRole] = useState("");
    const navigate = useNavigate();
    const { addAlert } = useSnackBar();

    const [selectedUser, setSelectedUser] = useState("");

    const [popoverOpen, setPopoverOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

 
    const disableUser = async(id) => {
        const res = await AuthHelper.disableUser(id);
        if(res.error)addAlert({
            severity: 'error',
            message: res.error
        })
        else addAlert({
            severity: 'success',
            message: res.message
        })
    }
    const resetPassword = async(id) => {
        console.log(selectedUser);
        try{
            const res = await AuthHelper.resetPassword(id);
            if(res.error)addAlert({
                severity: 'error',
                message: res.error
            })
            else addAlert({
                severity: 'success',
                message: res.message
            })
            setPopoverOpen(false);

        }catch(ex){
            addAlert({
                severity: 'error',
                message: 'Non è possibile modificare la password dell\'utente nello stato corrente'
            })
            setPopoverOpen(false);
        }
        
    }
    const columns = [
        { field: 'id', headerName: 'ID', width: 300, headerClassName: 'data-grid-header' },
        { field: 'name', headerName: 'Nome', width: 200, headerClassName: 'data-grid-header' },
        { field: 'family_name', headerName: 'Cognome', width: 200, headerClassName: 'data-grid-header' },
        { field: 'custom:schoolName', headerName: 'Nome Centro', width: 200, headerClassName: 'data-grid-header' },
        { field: 'custom:website', headerName: 'Sito web', width: 200, headerClassName: 'data-grid-header' },
        { field: 'custom:città', headerName: 'Città', width: 200, headerClassName: 'data-grid-header' },
        { field: 'custom:address', headerName: 'Indirizzo', width: 200, headerClassName: 'data-grid-header' },
        { field: 'email', headerName: 'E-mail', width: 200, headerClassName: 'data-grid-header' },
        { field: 'action', headerName: 'Azioni', renderCell: (params) => {
            return (
            role === 'ADMIN'  && params.row['enabled'] && 
             (<Button onClick={(e) => {setSelectedUser(params.row['id']); setPopoverOpen(true)}}>...</Button>
             )
        )}
    }
    ];

    const getUsers = async() => {
        const authIstance = AuthHelper.getInstance();
        const _users = await AuthHelper.getUsers();
        const _remap = [..._users.map(x => x.Attributes.map(y => ({[y.Name]: y.Value})))];
        const _usersRemapped = [];
        let i = 0;
        
        for(let _remapped of _remap){
            const _single_user = {};
            for(let object of _remapped){
                _single_user[Object.keys(object)[0]] = object[Object.keys(object)[0]];
                if(Object.keys(object)[0] === 'sub'){
                    _single_user.id = object.sub;
                }
            }
            console.log('UTENTE', _single_user);
            if(_single_user['custom:is_school'] === 'true'){
                _usersRemapped.push(_single_user);
            }
            
            i += 1;
        }
        setUsers(_usersRemapped);
        setLoading(false);
    }
    useEffect(() => {
        getUsers();
        builder('users.add');
    }, [])

    const handleClick = (data = undefined, name = null) => {
        store.dispatch(clear())
        builder('users.add');
        if(data){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, "Crea Utente");

    }
    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem'}}>
    <Typography variant="h4" color="primary" fontWeight="bold" textAlign="left" sx={{paddingBottom: '2rem', fontWeight: 'bold'}}>Lista Centri Formazione</Typography>
    {loading && <ListSkeleton />}
    {!loading && <DataTable  data={users} columns={columns} onClick={() => null}/>}
    <FloatingActionButtons onClick={() => handleClick()}/>
    <Dialog open={popoverOpen} onClose={() => setPopoverOpen(false)}>
        <DialogTitle sx={{color: '#000', fontWeight: 'bold'}}>Azioni</DialogTitle>
        <List>
            <ListItem sx={{color: '#000', pt: 0, mt: 0}}>
                <ListItemButton onClick={() => disableUser(selectedUser)} >
                    Disabilita    
                </ListItemButton>
            </ListItem>
            <ListItem sx={{color: '#000'}}>
                <ListItemButton onClick={() => resetPassword(selectedUser)} >
                    Reset Password
                </ListItemButton>
            </ListItem>
        </List>
    {/* <Menu
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => setPopoverOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {params.row['enabled'] ? <MenuItem onClick={() => disableUser(params.row['id'])}>Disabilita</MenuItem> : null}
        <MenuItem onClick={() => resetPassword(params.row['id'])}>Reset Password</MenuItem>
      </Menu> */}
    </Dialog>
    </Container>);
}

export default ListLeads;