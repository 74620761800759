
import { render } from 'react-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { memo } from 'react'
import { Box } from './FormBuilder/Box'
import { Dustbin } from './FormBuilder/Dustbin'
import { FormProvider } from '../../providers/FormProvider'
import { useForm } from '../../providers/consumers/useForm'
import { Button } from './FormBuilder/SaveForm'

const CreateForm = () => {
    return (
        <FormProvider>
            <DndProvider backend={HTML5Backend}>
                <div style={{display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{ overflow: 'hidden', clear: 'both' }}>
                        <Dustbin />
                    </div>
                    <div style={{ overflow: 'hidden', clear: 'both', display: 'flex', flexDirection: 'column'}}>
                        <Box name="Input" />
                        <Box name="Select" />
                        <Box name="Radio" />

                        <Button></Button>
                    </div>
                </div>
            </DndProvider>
        </FormProvider>
        
    )
}

export default CreateForm;