import { useDrag } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
import { useForm } from '../../../providers/consumers/useForm'
const style = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  cursor: 'move',
  float: 'left',
}

export const Button = function Box({ name }) {
    const {pushElement, elements} = useForm();
    
    return (
        <button onClick={() => console.log(elements)}>Ciao</button>
    )
}
