import { Container } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom"
import { ChildRouter } from "../../router/DashboardRouter"
import { useDashboardDrawer } from "../providers/consumers/useDashboardDrawer"
import { useNavigate } from "react-router-dom";
import { ResponsiveAppBar } from "../components/Navbar";

const Dashboard = () => {
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const navigate = useNavigate();

    /**
     * KICKING OUT NOT AUTHENTICATED USERS
     */
    if(!localStorage.getItem('cruddy-apiKey')){
        navigate('/');
    }
    /**
     * ************************************
     */
    React.useEffect(() => {
        addRouter(navigate);
        
    }, [pushMobileOpen, defaultOpen, addRouter, navigate]);

    return(
    <React.Fragment>
        <div className={"dashboard-body"}>
            <ResponsiveAppBar />
            <Container sx={{
                maxWidth: '100% !important',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
            }}>
                <Routes>
                    {ChildRouter.map(route => (<Route path={`${route.path}`} element={route.element}></Route>))}
                    <Route path="richieste" element={() => <p>Ciao</p>} />
                </Routes>
                
            </Container>
            
        </div>
    </React.Fragment>
 )
   
    
}
export { Dashboard } ;