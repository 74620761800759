import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import { useState } from "react";
import { Button, Container, Typography, Box, Tab, Tabs, styled, TextField } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSelector } from "react-redux";
import ListTeachers from "../teachers/ListTeachers";
import ListAule from "../aule/ListAule";
import ListDirettori from "../direttore/ListDirettori";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/it';
import corsi from "./corsi";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { AuthHelper } from "../../controllers/AuthHelper";
import { useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { LoadingButton } from "@mui/lab";

const Accreditamento = () => {
    
    const [accreditamento, setAccreditamento] = useState({});
    const [generalCourses, setGeneralCourses] = useState([]);
    const [loading, setLoading] = useState(false);

    const getStuff = async() => {
        const user = await AuthHelper.getUserFromIdToken();
        const certificates = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/certificates`);
        setGeneralCourses([...await certificates.json()]);
        const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/school/decretee/${user.Username}`)
        const stuff = await res.json();
        setAccreditamento({...stuff[0].reduce((acc, current) => {
            acc[current.course] = current;
            return acc;
        }, {})});
        // setAccreditamento((await res.json())[0])
    }

    const saveStuff = async() => {
        const user = await AuthHelper.getUserFromIdToken();
        Object.keys(accreditamento).forEach(async x => {
            setLoading(true);
            const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/school/decretee/${user.Username}`, {
                method: 'PUT',
                body: JSON.stringify({
                    ...accreditamento[x],
                    course: x
                })
            })
            await getStuff();
            setLoading(false);
        });
    }
    useEffect(() => {
        getStuff()
    }, [])

    const changeText = (e, idx) => {
        const bak = {...accreditamento};
        if(!bak[idx])bak[idx] = {};
        bak[idx]['decrete'] = e.target.value;
        console.log(bak);
        setAccreditamento({...bak});
    }
    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem'}}>
        <div style={{width: '100%', textAlign: 'left', marginBottom: 24}}>
            <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', gap: 12, width: '50%'}}>
                <h1 style={{textAlign: 'left', marginBottom: 0}}>Decreti accreditamento corsi</h1>
                <LoadingButton variant="contained" loading={loading} onClick={() => saveStuff()}>Salva</LoadingButton>
            </div>
            <hr style={{width: '50%', float: 'left'}}></hr>
        </div> <br/>
        {generalCourses.map((course, idx) => (
            <div style={{display: 'flex', gap: 12, marginBottom: 18}}>
                <h3>{course['nome corso']}: </h3>
                <TextField sx={{textAlign: 'left'}} minRows={3} multiline onChange={(e) => changeText(e, course['id'])} value={accreditamento[course['id']]?.['decrete']}></TextField>
            </div>
        ))}
    </Container>);
}

export default Accreditamento;