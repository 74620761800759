import * as React from 'react';

import {
  DataGridPro,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { Pagination, PaginationItem, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { LicenseInfo } from '@mui/x-license-pro';
import { AuthHelper } from '../controllers/AuthHelper';

LicenseInfo.setLicenseKey('cf912f115a7516047667546909bbe6a2Tz02ODgwNCxFPTE3MTg1MzkxNzExNTQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const localizedTextsMap = {
  columnMenuUnsort: "Non ordinato",
  columnMenuSortAsc: "Ordine crescente",
  columnMenuSortDesc: "Ordine decrescente",
  columnMenuFilter: "Filtro",
  columnMenuHideColumn: "Nascondi",
  columnMenuShowColumns: "Mostra colonne"
};



const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  border: 0,
  color: 'rgba(0,0,0,.85)',
 
  tableRoot: {
    width: "100%",
  },
 
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid ${
      theme.palette.mode === 'light' ? '#e0e0e0' : '#9f9f9f'
    }`,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${
      theme.palette.mode === 'light' ? '#e0e0e0' : '#9f9f9f'
    }`,
  },
  '& .MuiDataGrid-cell': {
    color:'rgba(0,0,0,0.65)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
}));

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}


export default function DataTable(props) {
  const navigate = useNavigate();

  const [role, setRole] = React.useState("");

  React.useEffect(() => {
    const attributes = AuthHelper.getUserFromIdToken().then((res) => {
      setRole(res.UserAttributes[1].Value);
      console.log(res.UserAttributes[1].Value)
    })
  }, [])


  const getClickHandler = (row) => {
    console.log(props.onClick);
    if(props.onClick === null)return;
    if(typeof props.onClick === 'object'){
      if(props.onClick?.action === 'navigate'){
        if(!row.row.leadObject){
          navigate(props.onClick.path.replace(':id', row.row.id), {
            state: {
              user: row.row
            }
          });
        }else{
          navigate(`/dashboard/leads/${row.row.leadObject.id}`, {
            state: {
              user: row.row.leadObject
            }
          })
        }
        
        console.log(row);
      }
    }else{
      props.handleClick(row);
    }
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <div style={{height: '90vh' }}>
        <StyledDataGrid
          rows={props.data}
          columns={props.columns}
          sx={{
              backgroundColor: '#fff',
              border: '1px solid #eee',
              borderRadius: 0,
              color: '#2f2f2f'
          }}
          ref={props.ref}
          localeText={localizedTextsMap}
          pageSize={15}
          rowsPerPageOptions={[5]}
          checkboxSelection
          pagination
          onRowClick={(row) => getClickHandler(row)}
          slots={role === 'AGENTE' || role === 'AGENZIA' || role === 'TEAM LEADER AGENZIA' ? null :{ toolbar: CustomToolbar }}
          onRowSelectionModelChange={props.onSelectionChange}
          onSelectionModelChange={props.onSelectionChange}
          components={{
            Pagination: CustomPagination
          }}
          initialState={props.initialState}
          rowHeight={props.rowHeight ? props.rowHeight : 52}
        />
      
    </div>
  );
}