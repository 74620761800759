import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import axios from "axios";
import { useState } from "react";
import DataTable from "../../components/Table";
import { Button, Container, Typography } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSelector } from "react-redux";
import GzipHelper from "../../controllers/GzipHelper";

const ListCustomers = () => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder();

    const elements = useSelector(state => state.elements);

    const columns = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
        { field: 'name', headerName: 'Nome', width: 200, headerClassName: 'data-grid-header' },
        { field: 'Categoria', headerName: 'Categoria', width: 200, headerClassName: 'data-grid-header' },
        { field: 'Sottocategoria', headerName: 'Sottocategoria', width: 150, headerClassName: 'data-grid-header'},
        { field: 'Certificati', headerName: 'Certificati',  valueGetter: (params) => params.row['Certificati'].map(x => x.name).join(','), width: 150, headerClassName: 'data-grid-header'},
    ];

    const getCustomers = async() => {
        const res = await axios.get('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/career-path');
        setCustomers([...res.data]);
        setLoading(false);
    }
    useEffect(() => {
        getCustomers();
        store.dispatch(clear())
        builder('customers.add');
    }, [])

    const handleClick = (data, name = null) => {
        store.dispatch(clear())
        builder('customers.add');
        if(data.row){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, name ? name : "Modifica ruolo", data.row);

    }
    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem'}}>
    
    <Typography variant="h4" color="primary" fontWeight="bold" textAlign="left" sx={{paddingBottom: '2rem', fontWeight: 'bold'}}>Career Path</Typography>
    
    {loading && <ListSkeleton />}
    {!loading && <DataTable data={customers} columns={columns} handleClick={handleClick} actions={[
        {type: 'select', name: 'Azioni'}
    ]}/>}
    <FloatingActionButtons onClick={() => handleClick([], "Crea Ruolo")}/>
    </Container>);
}

export default ListCustomers;