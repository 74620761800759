import { Alert, Snackbar } from "@mui/material";
import { createContext, useState } from "react";

export const SnackBarContext = createContext();

export const SnackBarProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);
  const [open, setOpen] = useState(true);
  const addAlert = (alert) => {setAlerts([...alerts, alert]); setOpen(true)};
  
  return (
    <SnackBarContext.Provider value={{addAlert}}>
      {children}
      {alerts.map((alert, i) => 
          <Snackbar 
              key={`snackbar-${i}`} 
              open={open} 
              autoHideDuration={3000} 
              onClose={() => setOpen(false)} 
              anchorOrigin={{vertical: "top", horizontal: "center"}}>
              <Alert onClose={() => setOpen(false)} severity={alert.severity}>
                  {alert.message}
              </Alert> 
          </Snackbar>)
      }
    </SnackBarContext.Provider>
  )
}