import { useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
import { useForm } from '../../../providers/consumers/useForm';
import { Box } from './Box';
const style = {
  height: '500px',
  width: '1000px',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  marginTop: '2.5rem',
  color: 'white',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
}
export const Dustbin = () => {
  const {elements} = useForm();
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.BOX,
    drop: (item) => {window.setTimeout(() => console.log(elements), 50); return {name: 'Dustbin'}},
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))
  const isActive = canDrop && isOver
  let backgroundColor = '#222'
  if (isActive) {
    backgroundColor = 'darkgreen'
  } else if (canDrop) {
    backgroundColor = 'darkkhaki'
  }
  return (
    <div ref={drop} style={{ ...style, backgroundColor }} data-testid="dustbin">
      {isActive ? 'Release to drop' : 'Drag a box here'}
      {elements ? elements.map(element => (
        <div>
            {element.name}
        </div>
      )) : null}
    </div>
  )
}