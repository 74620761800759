import React from "react";
import { Box, CircularProgress, Container, TextField, Typography } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { Colors, Gaps, Variables } from "../misc/variables";
import { AuthHelper } from "./controllers/AuthHelper";
import { useSnackBar } from "./providers/consumers/useSnackBar";
import { useNavigate, useLocation } from "react-router-dom";
import { useDashboardDrawer } from "./providers/consumers/useDashboardDrawer";

const helper = AuthHelper.getInstance();

const Login = () => {
    
    const [handleForm, setHandleForm] = React.useState({
        Username: "",
        Password: "",
    });
    const [isFormLoading, setFormLoading] = React.useState(false);
    const [changePassword, setChangePassword] = React.useState(false);

    const { addAlert } = useSnackBar();
    const { pushMobileOpen } = useDashboardDrawer(false);
    const location = useLocation();
    const navigate = useNavigate();

    const handleInput = (key, value) => {
        const form = handleForm;
        form[key] = value;
        setHandleForm({...form});
    }
    
    const handlePasswordReset = async () => {
        setFormLoading(true);
        await helper.changePassword(handleForm.Password);
        window.location.reload();
    }

    const handleLogin = async () => {
        setFormLoading(true);
        const loginRes = await helper.login(handleForm);
        switch(loginRes.status){
            case 'error':
                addAlert({message: 'Credenziali non corrette', severity: 'error'});
                break;
            case 'new_password':
                setChangePassword(true);
                handleInput('Password', '');
                addAlert({message: 'è necessario cambiare la password', severity: 'warning'});
                break;
            case 'ok':
                addAlert({message: 'Accesso effettuato', severity: 'success'});
                navigate('dashboard');
                break;
            default:
                addAlert({message: 'Errore server, rivolgersi allo sviluppatore.', severity: 'error'});
                break;
        }
        
        setFormLoading(false);
    }

    React.useEffect(() => {
        pushMobileOpen(false);
        const urlParams = new URLSearchParams(location.search);
        let apiKey = urlParams.get('token') ? urlParams.get('token') : localStorage.getItem('cruddy-apiKey');
        if(apiKey) {
            localStorage.setItem('cruddy-apiKey', apiKey)
            AuthHelper.getUserFromIdToken(apiKey);
            navigate('dashboard');
        }else{
            window.location.href = `http://mase-sso.s3-website-eu-west-1.amazonaws.com/?return-url=${window.location.href}`
        }
       
    }, [navigate, pushMobileOpen]);

    return (
    <React.Fragment>
        <Container maxWidth="md">
            <Box sx={{ bgcolor: Colors.secondaryColor, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw'}}>
                <CircularProgress color="secondary" />
            </Box>
        </Container>       
    </React.Fragment>
    )
}

export { Login };