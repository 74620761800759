import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import axios from "axios";
import { useState } from "react";
import DataTable from "../../components/Table";
import { Typography } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";

const Profile = () => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder('customers.add');

    const getCustomers = async() => {
        const res = await axios.get('https://l5cgmsv2il.execute-api.eu-west-1.amazonaws.com/dev/api/v1/customer');
        setLoading(false);
    }
    useEffect(() => {
        getCustomers();
    }, [])
    return (<>
    <Typography variant="h4" color="primary" fontWeight="bold" textAlign="left" sx={{paddingBottom: '2rem', fontWeight: 'bold'}}>Lista committenti</Typography>
    {loading && <ListSkeleton />}
    {!loading && <DataTable />}
    <FloatingActionButtons onClick={() => pushMobileOpen(true, 500, "Crea committente", builder, refs)}/>
    </>);
}

export default Profile;