import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import { useState } from "react";
import { Button, Container, Typography, Box, Tab, Tabs, Rating } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSelector } from "react-redux";
import ListTeachers from "../teachers/ListTeachers";
import ListAule from "../aule/ListAule";
import ListDirettori from "../direttore/ListDirettori";

import DataTable from "../../components/Table";
import moment from "moment";
import { AuthHelper } from "../../controllers/AuthHelper";


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
const ListReviews = () => {
    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(false);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder();
    const [value, setValue] = useState(0);
    const [recensioni, setReviews] = useState([]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const findReviews = async() => {
      const user = await AuthHelper.getUserFromIdToken();

      const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/prenotations/review/${user.Username}`);
      const json = await res.json();
      setReviews([...json]);
    }
    const elements = useSelector(state => state.elements);

    const columns = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header'},
        { field: 'user', headerName: 'Utente', width: 200, headerClassName: 'data-grid-header'},
        { field: 'utilita', headerName: 'Utilità', width: 200, headerClassName: 'data-grid-header', renderCell: (params) => (
          <Rating readOnly value={params.row.utilita} />
        )},
        { field: 'didattica', headerName: 'Didattica e docenti', width: 200, headerClassName: 'data-grid-header', renderCell: (params) => (
          <Rating readOnly value={params.row.didattica} />
        )},
        { field: 'materiale', headerName: 'Materiale Didattico', width: 200, headerClassName: 'data-grid-header', renderCell: (params) => (
          <Rating readOnly value={params.row.materiale} />
        )},
        { field: 'servizi', headerName: 'Servizi e struttura', width: 200, headerClassName: 'data-grid-header', renderCell: (params) => (
          <Rating readOnly value={params.row.servizi} />
        )},
        { field: 'score', headerName: 'Score finale', width: 200, headerClassName: 'data-grid-header', renderCell: (params) => (
          <Rating readOnly value={params.row.score} precision={0.5} />
        )},
        {
          field: 'text', headerName: 'Recensione', width: 300, headerClassName: 'data-grid-header'
        },
        { field: 'createdAt', headerName: 'Data invio', width: 200, headerClassName: 'data-grid-header',valueGetter: (value) => moment(value).format('DD/MM/YYYY') }

    ];

    const getCertificates = async() => {
       
    }
    useEffect(() => {
        findReviews();
        store.dispatch(clear())
        builder('promo.add');
    }, [])

    const handleClick = (data, name = null) => {
        store.dispatch(clear())
        builder('promo.add');
        if(data.row){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, name ? name : "Modifica Corso", data.row);

    }

    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem'}}>
    
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Lista recensioni" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {loading && <ListSkeleton />}
        {/* {!loading && <DataTable data={certificates} columns={columns} handleClick={handleClick} actions={[
            {type: 'select', name: 'Azioni'}
        ]}/>} */}
        {!loading && <DataTable data={recensioni} columns={columns} handleClick={handleClick} actions={[
        {type: 'select', name: 'Azioni'}
        ]}/>}
      </CustomTabPanel>
   
    
    </Container>);
}

export default ListReviews;