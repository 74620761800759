import pako from 'pako';
export default class GzipHelper {

    constructor(){

    }

    static async unzip(payload){
        const strData = atob(payload.base64);

        // Convert binary string to character-number array
        const charData = strData.split("").map((x) => { return x.charCodeAt(0); });

        // Turn number array into byte-array
        const binData = new Uint8Array(charData);

        return JSON.parse(pako.inflate(binData, { to: "string" }));
    }
}