import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";



export default function FloatingActionButtons(props) {
  const navigate = useNavigate();
  return (
    <Box sx={{ '& > :not(style)': { m: 1 }, position: 'fixed', bottom: '2rem', right: '2rem', zIndex: 9999}} >
      <Fab color="primary" aria-label="add" onClick={props.onClick}>
        <AddIcon />
      </Fab>
    </Box>
  );
}