import { createSlice, configureStore } from '@reduxjs/toolkit';

const initialState = {
    elements: [],
    fieldsData: {},
    request: {},
    fieldsRef: [],
    committenti: [],
    platformSelected: "",
    subSelected: "",
    course: ""
};

const formSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        addField(state, action){
            state.elements = [...state.elements, action.payload]
        },
        addCourse(state, action){
            console.log('REDUX ACTION', action);
            state.course = action.payload;
            console.log('STATE', state.course);

        },
        clear(state){
            state.elements = [];
            state.fieldsData = [];
            state.course = "";
        },
        addRequest(state, action){
            state.request = {...action.payload}
        },
        setFieldState(state, action){
            state.fieldsData = {...state.fieldsData, [action.payload.name]: action.payload.value}
        },
        setFieldsRef(state, action){
            state.fieldsRef = [...state.fieldsRef, action.payload]
        },
        removeField(state, action){
            console.log(action.payload)
            console.log(state.elements.filter(x => JSON.parse(x).fieldName !== action.payload));
            state.elements = [...state.elements.filter(x => JSON.parse(x).label !== action.payload)]
        },
        setCommittenti(state, action){
            state.elements = [...action.payload]
        },
        setPlatform(state, action){
            state.platformSelected = action.payload;
        },
        setSub(state, action){
            state.subSelected = action.payload;
        }
    }
});

const store = configureStore({
    reducer: formSlice.reducer
});

export const {addField, addCourse, addRequest, setFieldState, setFieldsRef, clear, removeField, setSub, setPlatform} = formSlice.actions;
export default store;
