import React from 'react';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';

const singleCourseTemplate = `<div style="padding-left: 32px; padding-right: 32px;margin: 0 auto; margin-top: 32px; text-align: center">
<table border=""1 style="margin: 0 auto; border: 1px solid #000; width: 100%;">
    <tr style="border-bottom: 1px solid #000">
      <td ><strong>Lezione nr.{no}</strong></td>
      <td colspan="3"><strong>Corso {course} ore {hour_range}</strong></td>
    </tr>
    <tr>
        <td colspan="4" style="font-size: 14px; text-align: left;">
            Programma: {description}
        </td>
    </tr>
    <tr>
        <td style="text-align: right">Didattica:</td>
        <td>{theory}</td>
        <td style="text-align: right">Totale Ore:</td>
        <td>{hours}</td>
    </tr>
  </table>
</div>`

const Practical = ` <div style="padding-left: 32px; padding-right: 32px;margin: 0 auto; margin-top: 32px; text-align: center">
<table border="1" style="width: 100%;margin: 0 auto; border: 1px solid #000; width: 100%;">
    <tr style="border-bottom: 1px solid #000">
      <td ><strong>Prova pratica</strong></td>
      <td ><strong>Effettuata</strong></td>
      <td ><strong>Non Effettuata</strong></td>
      <td ><strong>Firma istruttore</strong></td>
    </tr>
    <tr>
        <td style="font-size: 12px">{name}</td>
        <td style="height: 50px"></td>
        <td style="height: 50px"></td>
        <td style="height: 50px"></td>
    </tr>
  </table>
</div>`

const Certificate = (partecipant) =>` <div style="border:1px solid transparent; margin-top: 0; height: 750px; padding-left: 35px; padding-right: 35px;" class="page html2pdf__page-break">
<div style="height: 1035px; display: block; margin-top: 15px;border: 5px solid {settings.color}; padding: 25px">
<div style="width: 100%; display: block; margin: 0 auto; text-align:center;">
            <img src="{logo}" style="height: 65px"/>
        </div>
        <div><strong>Registrato al n°{id_matricola}</strong></div>
        <i style="font-size: 12px;">Registered n.</i>
        <div style="margin-top: 12px; text-align: center"><strong>Attestato relativo al corso di {nome_corso}</strong></div>
        <div style="text-align:center"><i style="font-size:12px;">Statement in {nome_corso}</i></div>
        <div style="margin-top: 12px;"><span>Si certifica che il Sig.</span><strong style="margin-left: 12px">{first_name} {last_name}</strong></div>
        <div style="font-size: 12px"><i>We hereby certify that Mr.</i></div>
        <div><strong>nato a</strong><strong style="margin-left: 12px">{birth_place}&nbsp;&nbsp;({birth_province})</strong><strong style="margin-left: 120px">il {birthday}</strong></div>
        <div><i style="font-size:12px">born in</i><i style="margin-left: 225px; font-size: 12px;">on</i></div>
        <div style="margin-top: 6px"><strong style="font-size: 12px;">Iscritto nelle matricole del Compartimento marittimo di {compartment}</strong></div>
        <div><i style="font-size: 12px">Registered as seafarers at Harbor Master Office of</i></div>
        <div style="display: block; width: 450px; margin: 0 auto; padding-top: 12px">
            <div style="display: inline;">
                <div><strong>al n° {matricola}</strong><strong style="margin-left: 48px; font-size: 10px">Codice Fiscale:</strong><strong>&nbsp; {fiscal_code}</strong></div>
                <div><i style="font-size: 12px">at n.</i><i style="margin-left: 130px; font-size: 10px">Tax Code:</i></div>
            </div>
        </div>
        <div style="margin-top: 12px; font-size: 12px"><strong>Ha frequentato dal</strong> <strong style="margin-left: 45px; font-size: 14px">{start_day}</strong><strong style="margin-left: 45px">al</strong><strong style="margin-left: 45px; font-size: 14px">{exam_day}</strong><strong>&nbsp; con esito favorevole il corso</strong></div>
        <div style="font-size: 12px"><i style="font-size: 10px">Has attended from</i> <strong style="margin-left: 100px; font-size: 14px"></strong><i style="margin-left: 138px; font-size: 10px">to</i><strong style="margin-left: 45px; font-size: 14px"></strong><i style="font-size: 10px; margin-left: 140px">&nbsp; with favorable result the course</i></div>
        <div style="width: 300px; margin: 0 auto; text-align: center; padding-top: 14px; font-size: 16px"><strong>"{nome_corso}"</strong></div>
        <div style="margin-top:14px; font-size: 12px"><strong>Presso la società {academy}, riconosciuta dal Ministero delle Infrastrutture e dei Trasporti - Comando Generale del Corpo delle Capitanerie di Porto</strong></div>
        <div style="font-size: 10px"><i>At {academy}, recognized by Ministry of Infrastructure and Transport- Italian Coast Guard Headquarters</i></div>
        {decretee}
        <div style="margin-top: 80px; display: ruby-text; margin-bottom: 25px;">
            <div>
                <strong>Data del rilascio: {exam_day}</strong><br/>
                <i style="font-size: 10px">Date of issue</i>
            </div>
            <div>
                <strong>Data di scadenza: {expiry}</strong><br/>
                <i style="font-size: 10px">Date of expiry</i>
            </div>
        </div>
        <br />
        <div style="margin-top: 50px; display: ruby-text">
            <div style="width: 200px; font-size: 10px">
                <strong>IL DIRETTORE DEL CORSO</strong><br/>
                <i>Reponsible of training</i>
                <div style="padding-top: 32px; border-bottom:1px solid"></div>
                <i style="font-size: 8px; margin-top:5px; display:block;">{direttore}</i>
            </div>
            <div style="width: 200px; font-size: 10px; margin-left: 255px">
                <strong>Il presidente della commissione d'esame</strong><br/>
                <i>The chairman signature</i>
                <div style="padding-top: 32px; border-bottom:1px solid"></div>
                <i style="font-size: 8px; margin-top:5px; display:block;">{autorita_marittima}</i>
            </div>
            
        </div>
        <div style="width: 200px; font-size: 10px; margin-top: 12px">
            <strong>Firma del titolare dell'attestato</strong><br/>
            <i>Signature of the holder of the statement</i>
            <div style="padding-top: 32px; border-bottom:1px solid"></div>
        </div>
</div>

        </div>

        `
const Libretto = `<html>
    <body style="font-family: Arial, Helvetica, sans-serif; font-size: 14px;width: 800px;margin-top: 0;">
        {certificates}
    </body>
</html>`
function _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
       binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }
const savePdf = async (payload) => {
    const logo = await fetch('https://mase-storage.s3.eu-west-1.amazonaws.com/1716223770927.document-file.jpeg');
    console.log('CORSO:', payload);
    // const mappa = lessons.map((x, i) => singleCourseTemplate.replaceAll('{no}', i + 1).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    // const practicalMap = lessons.filter(x => x.pratica).map((x, i) => Practical.replaceAll('{name}', `Lezione nr. ${i + 1}`).replaceAll('{course}', course).replaceAll('{hour_range}', x.hour_ranges).replaceAll('{description}', x.description).replaceAll('{theory}', x.pratica ? 'Pratica' : 'Teoria').replaceAll('{hours}', x.hours)).join('\n');
    html2pdf()
    .set({
        filename: 'verbale_esame.pdf',
        html2canvas: { useCORS: true },
        pagebreak: {mode: 'legacy'}
    })
    .from(Libretto.
        replaceAll('{certificates}', payload.partecipant.map((partecipant, idx) => Certificate(partecipant).replaceAll('{autorita_marittima}', payload.course.dataValues.autorita_marittima)
        .replaceAll('{docente}', payload.teacher)
        .replaceAll('{direttore}', payload.director)
        .replaceAll('{nome_corso}', payload.nome_corso)
        .replaceAll('{id}', payload.id ? payload.id : '2024/1')
        .replaceAll('{exam_day}', `${moment(payload.course.dataValues.data_esame, 'DD/MM/YYYY').format('dddd, DD/MM/YYYY')}`)
        .replaceAll('{expiry}', `${payload.generalCourse['validita\''] === 99 ? 'Permanente' : moment(payload.course.dataValues.data_esame, 'DD/MM/YYYY').subtract(1, 'day').add(5, 'years').format('dddd')} ${payload.generalCourse['validita\''] === 99 ? '' : moment(payload.course.dataValues.data_esame, 'DD/MM/YYYY').subtract(1, 'day').add(5, 'years').format('DD/MM/YYYY')}`)
        .replaceAll('{academy}', `${payload.school.name}, ${payload.school.indirizzo_scuola}`)
        .replaceAll('{first_name}', `${partecipant.first_name}`)
        .replaceAll('{last_name}', `${partecipant.last_name}`)
        .replaceAll('{birth_place}', `${partecipant.birthplace}`)
        .replaceAll('{birth_province}', `${partecipant.birth_province}`)
        .replaceAll('{birth_province}', `${partecipant.birth_province}`)
        .replaceAll('{settings.color}', `${payload.settings?.color ? payload.settings?.color : '#000'}`)
        .replaceAll('{matricola}', `${partecipant.matricola}`)
        .replaceAll('{compartment}', `${partecipant.compartimento_marittimo}`)
        .replaceAll('{id_matricola}', `${(idx+1).toString().padStart(3, '0')}/${payload.id}`)
        .replaceAll('{fiscal_code}', `${partecipant.fiscal_code}`)
        .replaceAll('{decretee}', `${payload.decretee.replaceAll('{decree}', `${payload.decree.decrete}`)}`)
       
        .replaceAll('{birthday}', `${moment(partecipant.birthday).format('DD/MM/YYYY')}`)
        .replaceAll('{start_day}', `${moment(payload.course.dataValues.from).format('dddd')}, ${moment(payload.course.dataValues.from).format('DD/MM/YYYY')}`)
        // .replaceAll('{partecipants}', payload.partecipant.map((partecipant, idx) => singlePartecipant(partecipant, idx + 1, payload.id)).join('\n'))
        .replaceAll('{school_address}', payload.school.indirizzo_scuola)  
        .replaceAll('{logo}', payload.settings?.logo_top ? payload.settings?.logo_top : payload.school.logo)  ).join(''))
        
    ).toPdf().get('pdf').then(pdf => {
        window.open(pdf.output('bloburl'), '_blank');
    })
}
export { Libretto, savePdf }