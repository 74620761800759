import { createSlice, configureStore } from '@reduxjs/toolkit';

const initialState = {
    platformSelected: "",
    subSelected: ""
};

const platformSlice = createSlice({
    name: 'platform',
    initialState,
    reducers: {
        setPlatform(state, action){
            state.platformSelected = action.payload;
        },
        setSub(state, action){
            state.subSelected = action.payload;
        }
    }
});

const store = configureStore({
    reducer: platformSlice.reducer
});

export const {setPlatform, setSub} = platformSlice.actions;
export default store;
