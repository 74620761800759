import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import axios from "axios";
import { useState } from "react";
import DataTable from "../../components/Table";
import { Button, Container, Typography } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSelector } from "react-redux";
import GzipHelper from "../../controllers/GzipHelper";

const ListOffers = () => {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder();

    const elements = useSelector(state => state.elements);

    const columns = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
        { field: 'name', headerName: 'Nome', width: 200, headerClassName: 'data-grid-header' },
        { field: 'type', headerName: 'Tipo committente', width: 200, headerClassName: 'data-grid-header' },
        { field: 'Visibile Lead', headerName: 'Visibile Lead', width: 150, valueGetter: (params) => params.row['Visibile Lead'] === 'S' ? 'Abilitato' : 'Disabilitato', headerClassName: 'data-grid-header'},
        { field: 'Visibile contratto', headerName: 'Visibile Contratto',  valueGetter: (params) => params.row['Visibile contratto'] === 'S' ? 'Abilitato' : 'Disabilitato', width: 150, headerClassName: 'data-grid-header'},
        { field: 'status', headerName: 'Stato', valueGetter: (params) => params.row.status === 'S' ? 'Abilitato' : 'Disabilitato', width: 100, headerClassName: 'data-grid-header'}
    ];

    const getOffers = async() => {
        const res = await axios.get('https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/offers');
        const unzipped = await GzipHelper.unzip(res.data);
        setOffers([...unzipped]);
        setLoading(false);
    }
    useEffect(() => {
        getOffers();
        store.dispatch(clear())
        builder('offers.add');
    }, [])

    const handleClick = (data, name = null) => {
        store.dispatch(clear())
        builder('offers.add');
        if(data.row){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, name ? name : "Modifica offerta", data.row);

    }
    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem'}}>
    
    <Typography variant="h4" color="primary" fontWeight="bold" textAlign="left" sx={{paddingBottom: '2rem', fontWeight: 'bold'}}>Lista offerte</Typography>
    
    {loading && <ListSkeleton />}
    {!loading && <DataTable data={offers} columns={columns} handleClick={handleClick} actions={[
        {type: 'select', name: 'Azioni'}
    ]}/>}
    <FloatingActionButtons onClick={() => handleClick([], "Crea Offerta")}/>
    </Container>);
}

export default ListOffers;