import { createContext, useState } from "react";

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [elements, setElements] = useState([]);
  const pushElement = (element) => {elements.push(element);};
  return (
    <FormContext.Provider value={{pushElement, elements}}>
      {children}
    </FormContext.Provider>
  )
}