import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import { useState } from "react";
import { Button, Container, Typography, Box, Tab, Tabs, styled } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSelector } from "react-redux";
import ListTeachers from "../teachers/ListTeachers";
import ListAule from "../aule/ListAule";
import ListDirettori from "../direttore/ListDirettori";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/it';
import corsi from "./corsi";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { AuthHelper } from "../../controllers/AuthHelper";
import { useNavigate } from "react-router-dom";
import html2pdf from "html2pdf.js";

import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
moment.locale('it');
const localizer = momentLocalizer(moment)
const DnDCalendar = withDragAndDrop(Calendar);

const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: '#888',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#40a9ff',
    opacity: 1,
  },
  '&.Mui-selected': {
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
const ListCertificates = () => {
    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(false);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder();
    const [value, setValue] = useState(0);

    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const elements = useSelector(state => state.elements);

    const columns = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
        { field: 'name', headerName: 'Titolo', width: 200, headerClassName: 'data-grid-header' },
        { field: 'Certificato', headerName: 'Certificato', width: 200, headerClassName: 'data-grid-header' },
        { field: 'Docente', headerName: 'Docente', width: 200, headerClassName: 'data-grid-header' },
        { field: 'price', headerName: 'Prezzo', width: 200, headerClassName: 'data-grid-header' }

    ];


    const printCalendar = () => {
      const groupByMonth = (data) => data.reduce((acc, item) => {
        if(moment(item.from).isValid()){
          if(!acc[moment(item.from).format('MMMM')])acc[moment(item.from).format('MMMM')] = [];
          acc[moment(item.from).format('MMMM')].push(item);
        }
        return acc
      }, {})
      const parsedEvents = groupByMonth(certificates);
      const table = `<html>
                    <body style="text-align: center;display: flex; flex-direction: column; align-items:center;">
                      <h1 style="text-align: center">Calendario annuale corsi</h1>
                      {months}
                    </body>
                </html>`
      const singlePartial = `
                  <h2 style="width: 100%; text-align: center">{month}</h2>
                        <table style="border-collapse: collapse; border:1px solid #000; width: 100%;">
                            <thead style="border-bottom: 1px solid #000">
                                <tr>
                                    <th style="border-right: 1px solid #000; padding: 10px">Nome corso</th>
                                    <th style="border-right: 1px solid #000">Dal</th>
                                    <th>Al</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
      `;

      const partials = [];

      for(let event of Object.keys(parsedEvents)){
        const row = `<tr style="border-bottom:1px solid">
                                    <td style="border-right: 1px solid #000; padding: 10px">{course}</td>
                                    <td style="border-right: 1px solid #000; padding: 10px">{from}</td>
                                    <td style="padding: 10px">{to}</td>
                                </tr>`
        const rows = parsedEvents[event].sort((a,b) => moment(b.from) - moment(a.from)).reverse().map(item => row.replaceAll('{course}', item['nome corso']).replaceAll('{from}', moment(item['from']).format('DD/MM/YYYY')).replaceAll('{to}', moment(item['to']).format('DD/MM/YYYY')));

        const parsedPartial = singlePartial.replaceAll('{month}', `${event} ${moment().format('YYYY')}`).replaceAll('{rows}', rows.join('<br/>'));
        partials.push(parsedPartial);  
          
      }
      
      html2pdf().set({
        filename: 'calendario_capitaneria_2024.pdf',
        html2canvas: { useCORS: true },
      }).from(table.replaceAll('{months}', partials.join('')))
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        window.open(pdf.output('bloburl'), '_blank');
      });
    }
    const getCertificates = async() => {
        const user = await AuthHelper.getUserFromIdToken();
        const res = await (await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/courses/${user.Username}`)).json();
        setCertificates([...res]);
        setLoading(false);
    }
    useEffect(() => {
        getCertificates();
        store.dispatch(clear())
        builder('certificates.add');
    }, [])

    const handleClick = (data, name = null) => {
        store.dispatch(clear())
        builder('courses.add');
        if(data.row){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, name ? name : "Modifica Corso", data.row);

    }
    const onEventDrop = async(data) => {
      setLoading(true);
      const {start, end, event} = data;
      const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/career-path/course/dates/${event.id}`, {
        body: JSON.stringify({
          from: start,
          end: end
        }),
        method: 'PUT'
      });
      await getCertificates();
      setLoading(false);
    }
    const onEventResize = async (data) => {
      setLoading(true);
      const {start, end, event} = data;
      console.log(data);
      const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/career-path/course/dates/${event.id}`, {
        body: JSON.stringify({
          from: start,
          end: end
        }),
        method: 'PUT'
      });
      await getCertificates();
      setLoading(false);
    }
    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem'}}>
    <div style={{width: '100%', display: 'flex', justifyContent: 'start', gap: 24, marginBottom: 24}}>
      <Button sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}}>Inserisci Domanda
Accreditamento Docente</Button>
      <Button sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}}>Inserisci Domanda
Accreditamento Direttore</Button>
      <Button sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}}>Inserisci Domanda
Accreditamento Aule</Button>
<Button sx={{backgroundColor: 'rgb(191, 163, 73)', fontWeight: 'bold', paddingX: 4, paddingY: 1, borderRadius: 66, fontSize: 12}} onClick={() => printCalendar()}>Stampa calendario</Button>
    </div>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
        sx={{
          color: '#888'
        }}
        TabIndicatorProps={{
          style:{
            text: '#888'
          }
        }} value={value} onChange={handleChange} aria-label="basic tabs example">
          <AntTab label="Calendario Annuale Corsi" {...a11yProps(0)} />
          <AntTab  label="Database Docenti Accreditati" {...a11yProps(1)} />
          <AntTab  label="Database Aule Accreditate" {...a11yProps(2)} />
          <AntTab  label="Database Direttori corsi" {...a11yProps(2)} />

        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {loading && <ListSkeleton />}
        {/* {!loading && <DataTable data={certificates} columns={columns} handleClick={handleClick} actions={[
            {type: 'select', name: 'Azioni'}
        ]}/>} */}
        {!loading && <div style={{height: 700}}><DnDCalendar
            localizer={localizer}
            events={certificates.map(certificate => (
              {
                'title': certificate['nome corso'],
                'start': moment(certificate.from).toDate(),
                'end': moment(certificate.to).toDate(),
                'id': certificate.id,
                'course': certificate['course']
              }
            ))}
            onSelectEvent={(e) => navigate(`/dashboard/corso/${e.id}?nome_corso=${encodeURIComponent(e.title)}&id=${e.course}&start=${e.start}&end=${e.end}`)}
            defaultDate={new Date()}
            startAccessor="start"
            endAccessor="end"
            onEventResize={onEventResize}
            onEventDrop={onEventDrop}
            resizable
            popup={false}
            step={40}
            views={['month', 'day', 'agenda']}
            defaultView="month"
            style={{ height: 1080 }}
            /></div>}
        <FloatingActionButtons onClick={() => handleClick([], "Crea corso")}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ListTeachers />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ListAule />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <ListDirettori />
      </CustomTabPanel>
    
    </Container>);
}

export default ListCertificates;