import { useEffect } from "react";
import { ListSkeleton } from "../../skeletons/List";
import axios from "axios";
import { useState } from "react";
import DataTable from "../../components/Table";
import { Button, Container, Typography } from "@mui/material";
import FloatingActionButtons from "../../components/Fab";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import { useBuilder } from "../../../hooks/useBuilder";
import store, { clear, setFieldState } from "../../store/formStore";
import { useSelector } from "react-redux";
import GzipHelper from "../../controllers/GzipHelper";

const ListRequests = () => {
    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(true);
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();
    const {builder, form, refs} = useBuilder();

    const elements = useSelector(state => state.elements);

    const columns = [
        { field: 'id', headerName: 'ID', width: 125, headerClassName: 'data-grid-header' },
        { field: 'name', headerName: 'Titolo', width: 200, headerClassName: 'data-grid-header' },
        { field: 'Validità', headerName: 'Validità (anni)', width: 200, headerClassName: 'data-grid-header' },
        { field: 'Renewal', headerName: 'Renewal Method', width: 200, headerClassName: 'data-grid-header' },
        { field: 'Nave Chimichiera', headerName: 'Nave Chimichiera', width: 150, valueGetter: (params) => params.row['Nave Chimichiera'] === 'on' ? 'Obbligatorio' : 'Non obbligatorio', headerClassName: 'data-grid-header'},
        { field: 'Nave gasiera', headerName: 'Nave gasiera',  valueGetter: (params) => params.row['Nave gasiera'] === 'on' ? 'Obbligatorio' : 'Non obbligatorio', width: 150, headerClassName: 'data-grid-header'},
        { field: 'Nave general cargo', headerName: 'Nave general cargo',  valueGetter: (params) => params.row['Nave general cargo'] === 'on' ? 'Obbligatorio' : 'Non obbligatorio', width: 150, headerClassName: 'data-grid-header'},
        { field: 'Nave Passeggeri', headerName: 'Nave passeggeri',  valueGetter: (params) => params.row['Nave Passeggeri'] === 'on' ? 'Obbligatorio' : 'Non obbligatorio', width: 150, headerClassName: 'data-grid-header'},
        { field: 'Nave petroliera', headerName: 'Nave petroliera',  valueGetter: (params) => params.row['Nave petroliera'] === 'on' ? 'Obbligatorio' : 'Non obbligatorio', width: 150, headerClassName: 'data-grid-header'},
        { field: 'Nave porta contenitori', headerName: 'Nave porta contenitori',  valueGetter: (params) => params.row['Nave porta contenitori'] === 'on' ? 'Obbligatorio' : 'Non obbligatorio', width: 150, headerClassName: 'data-grid-header'},
        { field: 'Yachts Commerciali', headerName: 'Yachts Commerciali',  valueGetter: (params) => params.row['Yachts Commerciali'] === 'on' ? 'Obbligatorio' : 'Non obbligatorio', width: 150, headerClassName: 'data-grid-header'},

    ];

    const getCertificates = async() => {
        const res = await (await fetch('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/certificates')).json();
        setCertificates([...res]);
        setLoading(false);
    }
    useEffect(() => {
        getCertificates();
        store.dispatch(clear())
        builder('certificates.add');
    }, [])

    const handleClick = (data, name = null) => {
        store.dispatch(clear())
        builder('certificates.add');
        if(data.row){
            Object.keys(data.row).forEach((key) => {
                store.dispatch(setFieldState({name: key, value: data.row[key]}));
            });
        }
        pushMobileOpen(true, 500, name ? name : "Modifica Certificati", data.row);

    }
    return (<Container sx={{paddingLeft: 24, paddingRight: 24, minWidth: '100vw', marginTop: '3rem'}}>
    
    <Typography variant="h4" color="primary" fontWeight="bold" textAlign="left" sx={{paddingBottom: '2rem', fontWeight: 'bold'}}>Lista richieste iscrizioni</Typography>
    
    {loading && <ListSkeleton />}
    {!loading && <DataTable data={certificates} columns={columns} handleClick={handleClick} actions={[
        {type: 'select', name: 'Azioni'}
    ]}/>}
    <FloatingActionButtons onClick={() => handleClick([], "Crea certificato")}/>
    </Container>);
}

export default ListRequests;