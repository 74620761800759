export default [
    {
      'title': 'Salvataggio e Sopravvivenza',
      'allDay': true,
      'start': new Date(2024, 1, 0),
      'end': new Date(2024, 1, 1)
    },
    {
      'title': 'Radar ARPA',
      'start': new Date(2024, 1, 7),
      'end': new Date(2024, 1, 10)
    },
  
    {
      'title': 'Crowd & Crisis',
      'start': new Date(2016, 2, 11, 0, 0, 0),
      'end': new Date(2016, 2, 20, 0, 0, 0)
    },
  
    {
      'title': 'Salvataggio e Sopravvivenza',
      'start': new Date(2016, 10, 6, 0, 0, 0),
      'end': new Date(2016, 10, 11, 0, 0, 0)
    },
  
    {
      'title': 'Primo Soccorso elementare',
      'start': new Date(2024, 1, 9, 0, 0, 0),
      'end': new Date(2024, 1, 9, 0, 0, 0)
    },
    {
      'title': 'Primo soccorso Sanitario',
      'start': new Date(2024, 1, 10),
      'end': new Date(2024, 1, 10),
      desc: 'Big conference for important people'
    },
    {
      'title': 'Leadership ed operatività',
      'start': new Date(2024, 1, 12, 17, 0, 0, 0),
      'end': new Date(2024, 1, 12, 17, 10, 0, 0),
    },
    {
      'title': 'Radar ARPA SAR',
      'start': new Date(2024, 1, 12, 20, 0, 0, 0),
      'end': new Date(2024, 1, 12, 21, 0, 0, 0)
    },
    {
      'title': 'Corsi base',
      'start': new Date(2024, 1, 20, 19, 10, 0),
      'end': new Date(2024, 1, 22, 2, 0, 0)
    }
  ]
  