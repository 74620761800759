import { createBrowserRouter } from "react-router-dom";
import { Login } from "../views/Login";
import { Dashboard } from "../views/templates/Dashboard";

const Router = createBrowserRouter([
    {
        path: '/',
        element: <Login />
    },
    {
        path: '/dashboard/*',
        element: <Dashboard />
    
    }
])

export { Router };